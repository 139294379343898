.stickyH{
    position: fixed;
    width: calc(100% - 60px);
    top: 60px;
    z-index: 99;
    background:#FFFFFF;
}
.stickyEyeHeader{
    position: fixed;
    width: calc(100% - 60px);
    top: 110px;
    z-index: 99;
    background:#FFFFFF;
    padding-top:18px;
}