*,
*::before,
*::after {
  margin: 0;
  box-sizing: border-box;
}

#root {
  height: 100%;
}

body {
  height: 100vh;
}

.page-container {
  min-height: 100vh;
}

/* https://gitlab.com/catamphetamine/react-phone-number-input/blob/master/style.css */
.PhoneInputInput {
  min-height: 50px;
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0;
}
.labelStyles{
  color:#544F4F;
  font-size: 16px;
 font-style: normal;
 font-weight: 400;
 line-height: 16px; /* 150% */
 letter-spacing: 0.2px;
}
.injectionDescHelper{
  margin-left:0 !important;
  margin-right:0 !important;
}
.imgCrisp {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}